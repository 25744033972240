import Vue from 'vue'
import {Message} from 'element-ui'
import Router from 'vue-router'
Vue.use(Router)
// 在路由加载的时候首次进来要按需加载路由，首页加载慢的问题，不要使用import换成require
export const constantRouteMap = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: resolve => require(['@/views/index'], resolve),
    name: 'index',
    meta:{
      title:'PDD'
    },
    children: [
      {
        path: 'home',
        component: resolve => require(['@/views/home/index'], resolve),
        name: 'home'
      },
      {
        path: 'help',
        component: resolve => require(['@/views/help/index'], resolve),
        name: 'help'
      },
      {
        path: 'settlement',
        component: resolve => require(['@/views/settlement/index'], resolve),
        name: 'settlement'
      },
      {
        path: 'order-list',
        component: resolve => require(['@/views/orderList/index'], resolve),
        name: 'orderList'
      },
      {
        path: 'deal',
        component: resolve => require(['@/views/deal/index'], resolve),
        name: 'deal'
      },
      {
        path: 'user',
        component: resolve => require(['@/views/user/index'], resolve),
        name: 'user',
        redirect: '/index/user/info',
        children: [
          {
            path: 'info',
            component: resolve => require(['@/views/user/info'], resolve),
            name: 'info',
          },
          {
            path: 'cash-log',
            component: resolve => require(['@/views/user/cashLog'], resolve),
            name: 'cashLog',
          },
          {
            path: 'order-log',
            component: resolve => require(['@/views/user/orderLog'], resolve),
            name: 'orderLog',
          },
          {
            path: 'recharge-log',
            component: resolve => require(['@/views/user/rechargeLog'], resolve),
            name: 'rechargeLog',
          },
          {
            path: 'token',
            component: resolve => require(['@/views/user/token'], resolve),
            name: 'token',
          },
          {
            path: 'statement',
            component: resolve => require(['@/views/user/statement'], resolve),
            name: 'statement',
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: resolve => require(['@/views/login/index'], resolve),
    name: 'login',
    meta:{
      title:'登录'
    }
  },
  {
    path: '/register',
    component: resolve => require(['@/views/login/register'], resolve),
    name: 'register',
    meta:{
      title:'注册'
    }
  }
]
const router = new Router({
  mode: process.env.VUE_APP_MODE, // 根绝环境变量来区分是打包成APP还是网页版查看,history和mode模式
  routes: constantRouteMap
})

router.afterEach(() => {
  window,scrollTo(0,0)
})

// 路由前置守卫
router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title);
  const pathArr = ['/login', '/register']
  const token = window.localStorage.getItem('token')
  const stop = window.sessionStorage.getItem('stop')
  if (Number(stop) === 1) {
    Message('自动下单中，禁止其他操作')
    return
  }
  if (pathArr.includes(to.path)) return next()
  if (token) {
    next()
  } else {
    next('/login')
  }
})

export default router
