// 整合
import Vue from 'vue'
import Vuex from 'vuex'
// import { plugin } from './plugins/myPlugin'
// 引入 modules 下的所有 js 文件
const requires = require.context('./modules', false, /\.js$/)
const modules = requires.keys().reduce((prev, item) => {
  const moduleName = item.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = requires(item)
  prev[moduleName] = value.default
  return prev
}, {})

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    count: 123
  },
  modules
  // plugins: [plugin]
})

export default store
