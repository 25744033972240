import http from '../require'

// 会员资料
export const getUserinfo = data => http.get('/merchants/view-my', data)

export const getFinance = () => http.get('/finance?expand=financeType&sort=finance_type')

// 重置密码
export const reset = (data) => http.patch('/memberinfos/change-password', data)
// 个人资料
export const getMemberinfo = (data) => http.get('/memberinfo/view-my', data)
// 修改个人资料
export const putData = (data) => http.put('/memberinfos/update-my', data)
// 提现
export const reqWithdrawals = (data, type) => type === 'post' ? http.post('/withdrawals', data) : http.get('/withdrawals', data)
// 充值
export const memberCharge = (data, type) => type === 'post' ? http.post('/member-charges', data) : http.get('/member-charges', data)
export const chargeLog = () => http.get('/guijis')
// 转账
export const postTransfer = (data) => http.post('/transfers', data)
// 续费
export const chargeDay = (data) => http.get('/charge-days', data)
export const xufei = (data) => http.post('/member-charge-days', data)
export const getFinanceLog = (data) => http.get('/finance-logs', data)