import http from '../require'

// 订单
export const getOrders = (data) => http.get('/merchant-orders', data)
// 订单分析
export const getOrdersChat = (data) => http.get('/merchants/chart', data)

export const getOrdersProducts = (data) => http.get('/merchant-products', data)

export const getFinance = (data) => http.get('/merchants/finance-data', data)

export const getFinance1 = (id,data) => http.get('/merchants/'+id, data)

export const reqWithdrawals = (type, data) => type==='post' ? http.post('/withdrawals', data) : http.get('/withdrawals', data)

// 结算
export const postOrdersSettle = data => http.post('/orders/settle', data)

// 订单
export const reqOrders = (data, type, id) => {
  const url = '/orders'
  if (type === 'post') {
    return http.post(url, data)
  } else if (type === 'del') {
    return http.del(`${url}/${id}`)
  } else {
    if (id) {
      return http.get(`${url}/${id}`, data)
    } else {
      return http.get(url, data)
    }
  }
}