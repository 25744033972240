import http from '../require'

export const postRefund = data => http.post('/orders/refund-batch', data)
export const postRefund1 = (data, type) => type === 'post' ? http.post('/refund-goods', data) : http.get('/refund-goods', data)
export const postUpload = data => http.put('/refund-goods/set-wait-hanle/' + data)
export const postSettle = (data, type) => type === 'post' ? http.post('/order-settlements', data) : http.get('/order-settlements', data)
export const getSettle = (id, data) => http.get('/order-settlements/list/' + id, data)
export const getSettle1 = (id, data) => http.get('/order-settlements/' + id, data)
export const getOSettle = data => http.get('/order-settlements', data)
export const buyToken = data => http.post('/member-buy-tokens', data)
export const getToken = data => http.get('/member-tokens', data)
export const postToken = (id, data) => http.put('/member-tokens/' + id, data)
export const getAddress = data => http.get('/templete-addresses', data)
export const reqMemberYFT = (data, type) => type === 'post' ? http.post('/member-yfts', data) : http.get('/member-yfts', data)
export const getRefundCount = data => http.get('/order-settlements/refund-count', data)
export const getAutoConfig = data => http.get('/member-auto-configs', data)
export const postAuto = data => http.post('/member-auto-logs', data)
export const getRefunedTotal = data => http.get('/refund-goods/total', data)
