const state = {
  userInfo: JSON.parse(localStorage.getItem('userInfo')) || '',
  pwd: '',
  noConfig: JSON.parse(localStorage.getItem('noConfig')) || '',
  config: JSON.parse(localStorage.getItem('config')) || '',
  finances: JSON.parse(localStorage.getItem('finances')) || [],
  area: JSON.parse(window.sessionStorage.getItem('area')) || '',
  memberTypes: JSON.parse(sessionStorage.getItem('memberTypes')) || [],
  memberLV: JSON.parse(sessionStorage.getItem('memberLV')) || []
}

const mutations = {
  // 设置用户信息
  setUserInfo (state, info) {
    state.userInfo = info
    localStorage.setItem('userInfo', JSON.stringify(info))
  },
  // 交易密码
  setPassword (state, password) {
    state.pwd = password
  },
  setConfig (state, config) {
    state.config = config
    localStorage.setItem('config', JSON.stringify(config))
  },
  // 无需登录配置
  setNoConfig (state, noConfig) {
    state.noConfig = noConfig
    localStorage.setItem('noConfig', JSON.stringify(noConfig))
  },
  setFinances (state, finances) {
    state.finances = finances
    localStorage.setItem('finances', JSON.stringify(finances))
  },
  // 获取地区
  setArea (state, area) {
    state.area = area
    window.sessionStorage.setItem('area', JSON.stringify(area))
  },
  // 设置会员类型
  setMemberTypes (state, info) {
    state.memberTypes = info
    window.sessionStorage.setItem('memberTypes', JSON.stringify(info))
  },
  // 设置会员等级
  setMemberLV (state, info) {
    state.memberLV = info
    window.sessionStorage.setItem('memberLV', JSON.stringify(info))
  }
}

const actions = {}

const site = { state, mutations, actions }

export default site
