import http from '../require'

// 产品分组
export const getGroups = (data) => http.get('/product-groups?sort=product_group_sort', data)
// 产品
export const getProducts = (data) => http.get('/products', data)
// 产品详情
export const getProductsDes = (data) => http.get('/products/' + data)
// 登录
export const postLogin = (data) => http.post('/site/login', data)
// 品牌
export const getBrandsId = (data) => http.get('/brands/' + data)
// 获取配置
export const getConfig = (data) => http.get('/site/config', data)
export const needVerify = (data) => http.get('/memberinfos/account-need-verify', data)
export const getsecondVerify = (data) => http.get('/memberinfos/get-second-verify', data)
export const setsecondVerify = (data) => http.post('/memberinfos/set-second-verify', data)
export const getUsdtCny = (data) => http.get('/site/usdt-cny', data)
// 获取登录之后的配置
export const getVerifyConfig = (data) => http.get('/site/verify-config', data)
