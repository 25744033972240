import Vue from 'vue'
import App from './App.vue'
import * as echarts from 'echarts';
import router from './router'
import store from './store/index'
import './plugins/element'
import * as api from './api/api'
import utils from './api/utils'
import filter from './api/filters'
// 复制
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

// 引入样式
require('./assets/css/common.css')
require('./assets/css/icon.css')

Vue.use(VueClipboard)

Object.keys(filter).forEach(key => Vue.filter(key, filter[key]))
const arr = {
  utils: utils,
  echarts,
  api: api.default
}
for (const key in arr) {
  Object.defineProperty(Vue.prototype, `$${key}`, {
    get: () => arr[key]
  })
}
Vue.prototype.$bus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
