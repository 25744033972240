import moment from 'moment'
const seckillTabs = ['00:00', '10:00', '14:00', '20:00', '22:00']
const nowHM = moment().hour() + ':' + moment().minute()
const nowHS = moment().hour() + ':' + moment().minute() + ':' + moment().second()
const nowDate = `${moment().year()}/${moment().month() + 1}/${moment().date()}`
const tomoDate = `${moment().year()}/${moment().month() + 1}/${moment().add(1, 'day').day()}`
// 验证手机号
function isPhone (value) {
  return !!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))
}
// 验证密码
function isPwd (value) {
  return !(value.length < 6)
}
// 验证邮箱
function isEmail (value) {
  return !!(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value))
}
// 验证金额
function isCurrency (value) {
  return !!(/^[1-9]\d*00$/.test(value))
}
// 获取之后的6天
function getDate (val) {
  const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  const today = new Date()
  const targetday = today.getTime() + 1000 * 60 * 60 * 24 * val
  today.setTime(targetday)
  const tYear = today.getFullYear()
  const tMonth = today.getMonth() + 1
  const tDate = today.getDate()
  const date = new Date(`${tYear}/${tMonth}/${tDate}`)
  const days = date.getDay()
  let week = weeks[days]
  if (val === 0) {
    week = '今天'
  }
  return `${tMonth}月${tDate}日(${week})`
}
// 获取当前时间
function getNow () {
  const date = new Date()
  const year = date.getFullYear()
  const month = Minus(date.getMonth() + 1)
  const day = date.getDate()
  const hours = date.getHours()
  const min = date.getMinutes()
  const second = date.getSeconds()
  return `${year}-${month}-${day} ${hours}:${min}:${second}`
}
// 小于10的时间加0
function Minus (val) {
  return val < 9 ? `0${val}` : val
}
// 秒杀中获取当前的时间场次
function seckillNow () {
  let seckillIndex = 0
  let timeStatus = 0
  const lists = []
  let minIndex
  for (const i in seckillTabs) {
    const min = moment(nowHS, 'hh:mm:ss').diff(moment(seckillTabs[i] + ':00', 'hh:mm:ss'), 'second')
    if (min > 0) {
      lists.push(min)
      Math.min.apply(Math, lists.map(item => {
        minIndex = i
        return item
      }))
    }
  }
  const time = moment(`${seckillTabs[Number(minIndex) + 1]}`, 'hh:mm').diff(moment(nowHM, 'hh:mm'), 'minute')
  timeStatus = time * 60 * 1000
  seckillIndex = Number(minIndex)
  const date = {
    time: timeStatus,
    tabs: seckillTabs[seckillIndex],
    tabs1: seckillTabs[seckillIndex + 1]
  }
  return date
}
// 数组对象去重
function arrayReduce (arr) {
  const obj = {}
  const result = arr.reduce((item, next) => {
    obj[next.seckill_id] = obj[next.seckill_id] ? '' : true && item.push(next)
    return item
  }, [])
  return result
}
// 获取在秒杀时间内的数据
function seckillList (start, end, tabs, tabs1) {
  start = start + ':00'
  end = end + ':00'
  const nowHS = moment().hour() + ':' + moment().minute() + ':' + moment().second()
  if (moment(start, 'hh:mm:ss').diff(moment(`${tabs}:00`, 'hh:mm:ss'), 'second') > 0 &&
    moment(end, 'hh:mm:ss').diff(moment(`${tabs1}:00`, 'hh:mm:ss'), 'second') > 0 &&
    moment(start, 'hh:mm:ss').diff(moment(`${nowHS}`, 'hh:mm:ss'), 'second') < 0 &&
    moment(end, 'hh:mm:ss').diff(moment(`${nowHS}:00`, 'hh:mm:ss'), 'second') > 0) {
    return true
  } else {
    return false
  }
}

// 获取当前日期
function getNows (date1) {
  const date = new Date(date1)
  const year = date.getFullYear()
  const month = minus(date.getMonth() + 1)
  const day = minus(date.getDate())
  return `${year}-${month}-${day}`
}

// 小于10的时间加0
function minus (val) {
  return val < 10 ? `0${val}` : val
}

// 判断是否是微信
function isWeixin () {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) === 'micromessenger') {
    return true
  } else {
    return false
  }
}

// 判断是否已过期
function isExprise (date) {
  const now = new Date()
  const exprise = new Date(date)
  if (now.getTime() >= exprise.getTime()) {
    return true
  } else {
    return false
  }
}

function wxpay (params, callback) {
  if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady(params, callback), false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady(params, callback))
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(params, callback))
    }
  } else {
    onBridgeReady(params, callback)
  }
}

// 微信公众号中支付，使用微信JSAPI，需开通JSAPI支付接口，直接在所用到的页面调用该函数即可
function onBridgeReady (params, callback) {
  // let that = this
  // eslint-disable-next-line
  WeixinJSBridge.invoke(
    'getBrandWCPayRequest', {
      appId: params.appId, // 公众号名称，由商户传入
      timeStamp: params.timeStamp, // 支付签名时间戳
      nonceStr: params.nonceStr, // 支付签名随机串,不长于32位
      package: params.package, // 统一支付接口返回的prepay_id参数值
      signType: params.signType, // 签名方式，默认为SHA1,使用新版支付需传入MD5
      paySign: params.paySign // 支付签名
    },
    function (res) {
      callback(res)
    }
  )
}

export default {
  isPhone,
  isPwd,
  isEmail,
  isCurrency,
  getDate,
  getNow,
  seckillNow,
  arrayReduce,
  seckillList,
  tomoDate,
  nowDate,
  moment,
  seckillTabs,
  isWeixin,
  wxpay,
  getNows,
  isExprise
}
