import router from '../router'
import Axios from 'axios'
import { Message } from 'element-ui'
const baseURL = process.env.VUE_APP_API_URL
const imgUrl = process.env.VUE_APP_IMG_URL
export const request = (config1) => {
  const instance = Axios.create({
    baseURL,
    imgUrl,
    timeout: 5000
  })
  // 请求拦截
  instance.interceptors.request.use(config => {
    const token = window.localStorage.getItem('token')
    if (token) {
      config.headers.authorization = `Bearer ${token}`
      // 判断哪个页面需要添加支付密码
      if (config.url === '/withdrawals' || config.url === '/transfers' || config.url === '/exchanges' || config.url === '/member-buy-tokens') {
        // headers中添加自定义属性，必须是前台或者后台全局注册这个新增加的属性
        config.headers['trade-password'] = window.sessionStorage.getItem('pwd')
      }
    }
    return config
  }, error => Promise.reject(error))
  // 相应拦截
  instance.interceptors.response.use(res => {
    if (res.config.method === 'get') {
      if (res.status !== 200) return Message('请求失败')
      return res
    } else {
      return res.data
    }
  }, error => {
    if (error.response.data.status === 404) {
      Message(error.response.data.message)
    }
    if (error.response.data.code === '401.6') {
      return '交易密码错误'
    }
    if (error.response.data.code === 401) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      Message('登录失效， 重新登录')
      router.push('/login')
    }
    if (error.response.status === 500) {
      Message('服务器竟然开小差了')
      // router.back()
    }
    return error
  })
  return instance(config1)
}
